const typography = {
  fonts: {
    size: {
      small: '12px',
      small2: '14px',
      regular: '16px',
      large: '20px',
      large2: '30px',
    },
  },
}

export default typography
