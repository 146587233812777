import React from 'react'
import { sanitize } from 'isomorphic-dompurify'
import { useTranslation } from 'react-i18next'
import { CitationVersionWrapper } from './styles'

const CitationVersion = ({ text, type, selected, editThis, select }) => {
  // If 'text' comes in as '', we're assuming we don't have a way to show the content. But we can still edit.
  const { t } = useTranslation()

  return (
    <CitationVersionWrapper
      className={selected ? 'selected' : ''}
      onClick={e => {
        e.preventDefault()
        select()
      }}
    >
      {text ? (
        <input
          checked={selected}
          name="citation-version"
          onChange={e => {
            e.preventDefault()
          }}
          type="checkbox"
        />
      ) : null}
      {text ? (
        <div
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: sanitize(text),
          }}
        />
      ) : (
        <p>{t('modals.citationPopup.No formatted version available')}</p>
      )}
      <p>
        <strong>
          {type === 'original' && t('modals.citationPopup.Original')}
          {type === 'anystyle' && t('modals.citationPopup.AnyStyle')}
          {type === 'crossref' && t('modals.citationPopup.CrossRef')}
          {type === 'custom' && t('modals.citationPopup.Custom')}
        </strong>
      </p>
    </CitationVersionWrapper>
  )
}

export default CitationVersion
