import React from 'react'
import Tooltip from 'rc-tooltip'
import { StyledAlertCircle } from '../style'
import { useTranslation } from 'react-i18next'

const OverdueTooltip = ({ manuscript }) => {
  const { t } = useTranslation()
  return (
    <div>
      {manuscript.hasOverdueTasksForUser && (
        <Tooltip
          overlay={<p color="red">{t('dashboardPage.edit.Overdue Task')}</p>}
          overlayInnerStyle={{
            backgroundColor: 'White',
            borderColor: 'LightGray',
            fontWeight: 'bold',
            color: 'red',
          }}
          placement="top"
        >
          <StyledAlertCircle />
        </Tooltip>
      )}
    </div>
  )
}

export default OverdueTooltip
