/* eslint-disable camelcase */
const esLa = {
  translation: {
    msStatus: {
      new: 'No enviado',
      submitted: 'Enviado',
      accepted: 'Aceptado',
      evaluated: 'Evaluado',
      rejected: 'Rechazado',
      revise: 'Revisar',
      revising: 'Revisando',
      published: 'Publicado',
      unknown: 'Desconocido',
    },
    reviewerStatus: {
      invited: 'Invitado',
      rejected: 'Declinado',
      declined: 'Declinado',
      accepted: 'Aceptado',
      inProgress: 'En Progreso',
      completed: 'Completado',
      unanswered: 'Sin Respuesta',
    },
    common: {
      OK: 'OK',
      Cancel: 'Cancelar',
      'Enter search terms...': 'Ingrese términos de búsqueda...',
      surroundMultiword:
        'Agrupe frases de varias palabras entre comillas "". Excluya un término anteponiendo -. Especifique coincidencias alternativas usando O. Use * como comodín para el final de las palabras. Encierre subexpresiones entre paréntesis ().',
      noOption: 'Sin opción',
      danteRangeCalendar: {
        Presets: 'Preajustes',
        Today: 'Hoy',
        Yesterday: 'Ayer',
        'Past 7 days': 'Últimos 7 días',
        'Past 30 days': 'Últimos 30 días',
        'Past 90 days': 'Últimos 90 días',
        'Past year': 'Último año',
        Clear: 'Limpiar',
      },
      roles: {
        Admin: 'Administrador',
        'Group Manager': 'Gerente de Grupo',
        User: 'Usuario',
      },
      emailUpdate: {
        invalidEmail: 'El correo electrónico no es válido',
        emailTaken: 'El correo electrónico ya está en uso',
        smthWentWrong: 'Algo salió mal',
      },
      relativeDateStrings: {
        today: 'hoy',
        yesterday: 'ayer',
        daysAgo: 'hace {{count}} día',
        daysAgo_plural: 'hace {{count}} días',
      },
      recommendations: {
        Accept: 'Aceptar',
        Revise: 'Revisar',
        Reject: 'Rechazar',
      },
      teams: {
        assign: 'Asignar {{teamLabel}}...',
        'Senior Editor': 'Editor Senior',
        'Handling Editor': 'Editor de Tratamiento',
        Editor: 'Editor',
      },
      kanban: {
        'Last updated': 'Última actualización',
        'Invited via email': 'Invitado por correo electrónico',
      },
      days: {
        day: 'día',
        day_plural: 'días',
      },
    },
    leftMenu: {
      'Summary Info': 'Información Resumen',
      Manuscript: 'Manuscrito',
      Dashboard: 'Tablero',
      Manuscripts: 'Manuscritos',
      Reports: 'Informes',
      Settings: 'Configuración',
      Forms: 'Formularios',
      Submission: 'Envío',
      Review: 'Revisión',
      Decision: 'Decisión',
      Tasks: 'Tareas',
      Users: 'Usuarios',
      Configuration: 'Configuración',
      Emails: 'Correos Electrónicos',
      CMS: 'Gestor de Contenidos',
      Pages: 'Páginas',
      Layout: 'Diseño',
      'Go to your profile': 'Ir a tu perfil',
    },

    profilePage: {
      'Your profile': 'Tu perfil',
      'Profile: ': 'Perfil: ',
      Logout: 'Cerrar Sesión',
      Username: 'Nombre de Usuario',
      Email: 'Correo Electrónico',
      Language: 'Idioma',
      Change: 'Cambiar',
      usernameWarn:
        'No puede comenzar con un número ni empezar o terminar con espacios en blanco',
      userPrivilegeAlert: `Se Requieren Privilegios de Usuario
        <br /> Asegúrate de tener los permisos de rol adecuados o
        ponte en contacto con tu administrador del sistema para obtener ayuda.`,
      'Drop it here': 'Soltarlo aquí',
      'Change profile picture': 'Cambiar imagen de perfil',
      'Mute all discussion email notifications':
        'Silenciar todas las notificaciones de correo electrónico de discusión',
      ORCID: 'ORCID',
    },
    manuscriptsTable: {
      'No matching manuscripts were found':
        'No se encontraron manuscritos coincidentes',
      'Manuscript number': 'Número de Manuscrito',
      Created: 'Creado',
      Updated: 'Actualizado',
      'Last Status Update': 'Última Actualización de Estado',
      Status: 'Estado',
      'Your Status': 'Tu Estado',
      Title: 'Título',
      Version: 'Versión',
      Author: 'Autor',
      Editor: 'Editor',
      'Reviewer Status': 'Estado del Revisor',
      Actions: 'Acciones',
      Decision: 'DECISIÓN',
      Team: 'EQUIPO',
      'No results found': 'No se encontraron resultados',
      pagination: `Mostrando <strong>{{firstResult}}</strong> a <strong>{{lastResult}}</strong> de <strong>{{totalCount}}</strong> resultados`,
      reviewAccept: 'Aceptar',
      reviewReject: 'Rechazar',
      reviewDo: 'Hacer Revisión',
      reviewCompleted: 'Completado',
      reviewContinue: 'Continuar Revisión',
      all: 'Todo',
      Search: 'Buscar',
      actions: {
        Evaluation: 'Evaluación',
        Control: 'Control',
        View: 'Ver',
        Archive: 'Archivo',
        Production: 'Producción',
        Publish: 'Publicar',
        confirmArchive:
          'Por favor, confirma que deseas archivar este manuscrito',
        confirmArchiveButton: 'Archivar',
        cancelArchiveButton: 'Cancelar',
        'Publishing error': 'Error de Publicación',
        'Some targets failed to publish':
          'Algunos objetivos no pudieron ser publicados.',
      },
    },
    dashboardPage: {
      Dashboard: 'Tablero',
      'New submission': '+ Nueva presentación',
      'New Alerts': 'Nuevas Alertas',
      'My Submissions': 'Mis Presentaciones',
      'To Review': 'Para Revisión',
      "Manuscripts I'm Editor of": 'Manuscritos en los que soy Editor',
      mySubmissions: {
        'My Submissions': 'Mis Presentaciones',
      },
      toReview: {
        'To Review': 'Para Revisión',
      },
      edit: {
        "Manuscripts I'm editor of": 'Manuscritos en los que soy editor',
        'Overdue Task': 'Tarea vencida',
      },
    },
    reviewPage: {
      Versions: 'Versiones',
      'Anonymous Reviewer': 'Revisor Anónimo',
      Submit: 'Enviar',
    },
    reviewVerdict: {
      accept: 'aceptar',
      revise: 'revisar',
      reject: 'rechazar',
    },
    manuscriptsPage: {
      Manuscripts: 'Manuscritos',
      manuscriptInvalid:
        'Este manuscrito tiene campos incompletos o inválidos. Por favor, corrígelos e inténtalo de nuevo.',
      importPending: 'pendiente',
      Refreshing: 'Actualizando',
      Refresh: 'Actualizar',
      'Select All': 'Seleccionar Todo',
      selectedArticles: '{{count}} artículos seleccionados',
      Archive: 'Archivo',
    },
    decisionPage: {
      'Current version': 'Versión Actual',
      Team: 'Equipo',
      Decision: 'Decisión',
      'Manuscript text': 'Texto del Manuscrito',
      Metadata: 'Metadatos',
      'Tasks & Notifications': 'Tareas y Notificaciones',
      'Assign Editors': 'Asignar Editores',
      'Reviewer Status': 'Estado del Revisor',
      Version: 'Versión',
      'See Declined': 'Ver Rechazados ({{count}})',
      'Hide Declined': 'Ocultar Rechazados',
      'No Declined Reviewers': 'No hay Revisores Rechazados',
      'Invite Reviewers': 'Invitar Revisores',
      'New User': 'Nuevo Usuario',
      selectUser: 'Seleccionar...',
      'Invite reviewer': 'Invitar revisor',
      'Invite and Notify': 'Invitar y Notificar',
      'User email address opted out':
        'La dirección de correo electrónico del usuario ha sido excluida',
      inviteUser: {
        Email: 'Email',
        Name: 'Nombre',
      },
      declinedInvitation: 'Rechazado el {{dateString}}',
      'Invited via email': 'Invitado vía correo electrónico',
      'View Details': 'Ver Detalles',
      decisionTab: {
        'Archived version': 'Versión Archivada',
        notCurrentVersion:
          'Esta no es la versión actual, sino una versión archivada de solo lectura del manuscrito.',
        'Completed Reviews': 'Revisiones Completadas',
        noReviews: 'Aún no se han completado revisiones.',
        reviewNum: 'Revisión {{num}}',
        'Anonmyous Reviewer': 'Revisor Anónimo',
        'Hide review': 'Ocultar revisión',
        'Hide reviewer name': 'Ocultar nombre del revisor',
        reviewModalShow: 'Mostrar',
        reviewModalHide: 'Ocultar',
        Submit: 'Enviar',
        Publishing: 'Publicando',
        publishOnlyAccepted:
          'Solo puedes publicar las presentaciones aceptadas.',
        publishingNewEntry:
          'La publicación agregará una nueva entrada en el sitio web público y no se puede deshacer.',
        Publish: 'Publicar',
        Republish: 'Volver a Publicar',
        publishedOn: 'Esta presentación se publicó el {{date}}',
        doisToBeRegistered: 'DOIs a registrar: {{dois}}',
        noDoisToBeRegistered:
          'No se registrarán DOIs al momento de la publicación.',
      },
      metadataTab: {
        'Manuscript Number': 'Número de Manuscrito:',
      },
      tasksTab: {
        Notifications: 'Notificaciones',
        'New User': 'Nuevo Usuario',
        'Choose receiver': 'Elegir destinatario',
        'Choose notification template': 'Elegir plantilla de notificación',
        Notify: 'Notificar',
        'User email address opted out':
          'La dirección de correo electrónico del usuario ha sido excluida',
        Tasks: 'Tareas',
        newUser: {
          Email: 'Email',
          Name: 'Nombre',
        },
      },
      'Add another person': 'Agregar otra persona',
      'Delete this author': 'Eliminar este autor',
    },
    editorSection: {
      noFileLoaded: 'No se ha cargado ningún archivo de manuscrito',
      noSupportedView: 'No hay vista admitida del archivo',
    },
    cmsPage: {
      pages: {
        addNew: 'Agregar una nueva página',
        'New Page': 'Nueva Página',
        Pages: 'Páginas',
        Publish: 'Publicar',
        'Saving data': 'Guardando datos',
        Rebuilding: 'Reconstruyendo...',
        Published: 'Publicado',
        Save: 'Guardar',
        Delete: 'Eliminar',
        fields: {
          title: 'Título de la página*',
          url: 'URL',
        },
        'New edits on page': 'Nuevas ediciones en la página',
        'Edited on': 'Editado el {{date}}',
        'Published on': 'Publicado el {{date}}',
        'Not published yet': 'Aún no publicado',
      },
      layout: {
        Publish: 'Publicar',
        Layout: 'Diseño',
        'Saving data': 'Guardando datos',
        'Rebuilding Site': 'Reconstruyendo el Sitio...',
        Published: 'Publicado',
        'Brand logo': 'Logo de la Marca',
        'Brand Color': 'Color de la Marca',
        'Choose languages': 'Elegir idiomas',
        LanguagesDesc:
          'Puede seleccionar los idiomas de su sitio web y organizarlos en el orden deseado<br/>Si su sitio web solo tiene 1 idioma, puede dejar este campo en blanco',
        'Add language': 'Agregar idioma',
        'Remove language': 'quitar idioma',
        fields: {
          primaryColor: 'Color Primario',
          secondaryColor: 'Color Secundario',
        },
        Header: 'Encabezado',
        useCheckbox:
          'Utilice casillas de verificación para mostrar u ocultar la página en el menú. Arrastre y suelte para ordenarlos.',
        Footer: 'Pie de Página',
        Partners: 'Socios',
        'Footer Text': 'Texto del Pie de Página',
        'Footer Page links': 'Enlaces de Páginas en el Pie de Página',
      },
    },
    authorsInput: {
      firstName: {
        label: 'Nombre',
        placeholder: 'Ingresa el nombre...',
      },
      lastName: {
        label: 'Apellido',
        placeholder: 'Ingresa el apellido...',
      },
      email: {
        label: 'Correo Electrónico',
        placeholder: 'Ingresa el correo electrónico...',
      },
      affiliation: {
        label: 'Afiliación',
        placeholder: 'Ingresa la afiliación...',
      },
    },
    dragndrop: {
      'Drag and drop your files here': 'Arrastra y suelta tus archivos aquí',
      'Your file has been uploaded': 'Tu archivo ha sido cargado.',
      Remove: 'Eliminar',
    },
    productionPage: {
      Production: 'Producción',
      'No supported view of the file': 'No hay una vista admitida del archivo',
      Download: 'Descargar',
      Editor: 'Editor',
      'PagedJs Css': 'PagedJs Css',
      'PagedJs Html Template': 'PagedJs Html Template',
      'PagedJs Template Assets': 'PagedJs Template Assets',
      'PagedJs Metadata': 'PagedJs Metadata',
    },
    invitationResults: {
      author: 'autor',
      reviewer: 'revisor',
      declinedAndOptedOut:
        'Invitación {{invitationType}} rechazada y se excluyó',
      declined: 'Invitación {{invitationType}} rechazada',
      accepted: 'Invitación {{invitationType}} aceptada',
    },
    configPage: {
      Configuration: 'Configuración',
      'Instance Type': 'Tipo de Instancia',
      'Group Identity': 'Identidad del Grupo',
      'Brand name': 'Nombre de la Marca',
      'Brand primary colour': 'Color Primario de la Marca',
      'Brand secondary colour': 'Color Secundario de la Marca',
      ImmediateNotification:
        'Immediate Notification for users @mentioned in a message',
      Logo: 'Logotipo',
      Dashboard: 'Panel de Control',
      landingPage:
        'Página de Inicio para Usuarios del Gerente de Grupo al Iniciar Sesión',
      'Dashboard Page': 'Página de Inicio del Panel de Control',
      'Manuscript Page': 'Página de Manuscrito',
      pagesVisibleToRegistered:
        'Páginas del panel de control visibles para usuarios registrados',
      'My Submissions': 'Mis Envíos',
      'To Review': 'Por Revisar',
      "Manuscripts I'm editor of": 'Manuscritos de los que soy editor',
      'Manuscripts page': 'Página de Manuscritos',
      'List columns to display on the Manuscripts page':
        'Listar columnas para mostrar en la página de Manuscritos',
      numberOfManuscripts:
        'Número de manuscritos listados por página en la página de Manuscritos',
      hourManuscriptsImported:
        'Hora a la que los manuscritos se importan diariamente (UTC)',
      daysManuscriptRemain:
        'Número de días que un manuscrito debe permanecer en la página de Manuscritos antes de ser archivado automáticamente',
      importFromSematic:
        'Importar manuscritos de Sematic Scholar no más antiguos que ‘x’ días',
      newSubmissionActionVisisble:
        'Acción de "Agregar nuevo envío" visible en la página de Manuscritos',
      displayActionToSelect:
        'Mostrar acción para "Seleccionar" manuscritos para revisión desde la página de Manuscritos',
      importManuscriptsManually:
        'Importar manuscritos manualmente usando la acción "Actualizar"',
      'Control panel': 'Panel de Control',
      'Display manuscript short id': 'Mostrar ID corto del manuscrito',
      'Reviewers can see submitted reviews':
        'Los revisores pueden ver revisiones enviadas',
      'Authors can see individual peer reviews':
        'Los autores pueden ver revisiones de pares individuales',
      'Control pages visible to editors':
        'Páginas de control visibles para editores',
      Team: 'Equipo',
      Submission: 'Envío',
      allowToSubmitNewVersion:
        'Permitir a un autor enviar una nueva versión de su manuscrito en cualquier momento',
      'Review page': 'Página de Revisión',
      showSummary:
        'Los revisores pueden ver los datos del formulario de Decisión',
      Publishing: 'Publicación',
      Hypothesis: 'Hipótesis',
      'Hypothesis API key': 'Clave de API de Hipótesis',
      'Hypothesis group id': 'ID de Grupo de Hipótesis',
      shouldAllowTagging:
        'Aplicar etiquetas de Hipótesis en el formulario de envío',
      reverseFieldOrder:
        'Invertir el orden de campos del formulario de Envío/Decisión publicados en Hipótesis',
      Crossref: 'Crossref',
      journalName: 'Nombre de la Revista/Grupo',
      journalAbbreviatedName: 'Nombre Abreviado de la Revista',
      journalHomepage: 'Página de Inicio de la Revista',
      crossrefLogin: 'Nombre de Usuario de Crossref',
      crossrefPassword: 'Contraseña de Crossref',
      crossrefRegistrant: 'ID de Registrante de Crossref',
      crossrefDepositorName: 'Nombre del Depositante de Crossref',
      crossrefDepositorEmail: 'Dirección de Correo Electrónico del Depositante',
      publicationType: 'Seleccionar tipo de publicación',
      doiPrefix: 'Prefijo DOI de Crossref',
      publishedArticleLocationPrefix:
        'Prefijo de Ubicación de Artículos Publicados de Crossref',
      licenseUrl: 'URL de la Licencia de Publicación',
      useSandbox: 'Publicar en el entorno de prueba de Crossref',
      Webhook: 'Webhook',
      webhookUrl: 'URL del Webhook de Publicación',
      webhookToken: 'Token del Webhook de Publicación',
      webhookRef: 'Referencia del Webhook de Publicación',
      'Task Manager': 'Administrador de Tareas',
      teamTimezone:
        'Establecer zona horaria para las fechas de vencimiento del Administrador de Tareas',
      Emails: 'Correos Electrónicos',
      gmailAuthEmail: 'Dirección de Correo Electrónico de Gmail',
      gmailSenderEmail:
        'Dirección de Correo Electrónico del Remitente de Gmail',
      gmailAuthPassword: 'Contraseña de Gmail',
      eventNotification: 'Notificaciones de Eventos',
      reviewRejectedEmailTemplate:
        'El revisor rechaza una invitación a revisar',
      reviewerInvitationPrimaryEmailTemplate: 'Invitación al revisor',
      evaluationCompleteEmailTemplate: 'Revisión enviada',
      submissionConfirmationEmailTemplate: 'Manuscrito enviado',
      alertUnreadMessageDigestTemplate: 'Mensaje de discusión no leído',
      Reports: 'Reportes',
      reportShowInMenu:
        'El Gerente de Grupo y el administrador pueden acceder a los Reportes',
      'User Management': 'Gestión de Usuarios',
      userIsAdmin:
        'Todos los usuarios tienen roles de Gerente de Grupo y Administrador',
      kotahiApis: 'Journal website',
      tokens: 'URL',
      Submit: 'Enviar',
      article: 'artículo',
      'peer review': 'revisión de pares',
      showTabs: {
        Team: 'Equipo',
        Decision: 'Decisión',
        'Manuscript text': 'Texto del Manuscrito',
        Metadata: 'Metadatos',
        'Tasks & Notifications': 'Tareas y Notificaciones',
      },
      crossrefRetrievalEmail: 'Correo electrónico para búsqueda de citas',
      crossrefSearchResultCount:
        'Número de resultados a devolver de la búsqueda de citas',
      crossrefStyleName: 'Seleccionar formato de estilo para citas',
      crossrefLocaleName: 'Seleccionar configuración regional para citas',
      production: {
        Production: 'Producción',
        'Email to use for citation search':
          'Correo electrónico para búsqueda de citas',
        'Number of results to return from citation search':
          'Número de resultados a devolver de la búsqueda de citas',
        'Select style formatting for citations':
          'Seleccionar formato de estilo para citas',
        gost: 'Russian GOST',
        apa: 'American Psychological Association (APA)',
        cmos: 'Manual de Estilo de Chicago (CMOS)',
        cse: 'Consejo de Editores Científicos (CSE)',
        'Select locale for citations':
          'Seleccionar configuración regional para citas',
      },
      allowedIPs: 'Lista de IP del repositorio a las que se permite el acceso',
      api: 'API de Kotahi',
    },
    reportsPage: {
      Reports: 'Reportes',
      Show: 'Mostrar',
      activityForManuscripts: 'actividad para manuscritos que llegaron',
      activityForManuscriptsTooltip: `Las métricas se muestran para manuscritos que se ingresaron por primera vez
                    <br />
                    en el sistema entre estas fechas. Los límites de fecha son
                    <br />
                    a la medianoche en Tiempo Universal.`,
      'Editors workflow': 'Flujo de Trabajo de Editores',
      'All manuscripts': 'Todos los manuscritos',
      Submitted: 'Enviados',
      'Editor assigned': 'Editor asignado',
      'Decision complete': 'Decisión completa',
      Accepted: 'Aceptados',
      Published: 'Publicados',
      'Reviewers workflow': 'Flujo de Trabajo de Revisores',
      'Reviewer invited': 'Revisor invitado',
      'Invite accepted': 'Invitación aceptada',
      'Review completed': 'Revisión completada',
      'Manuscripts published today': 'Manuscritos publicados hoy',
      'From midnight local time': 'Desde la medianoche hora local',
      Average: 'Promedio',
      'Manuscripts in progress': 'Manuscritos en progreso',
      'Based on the selected date range':
        'Basado en el rango de fechas seleccionado',
      reviwingAndEditing:
        'Duraciones de revisión y edición para manuscritos individuales',
      'Days spent on': 'Días invertidos en',
      daysSpentReview: 'revisión,',
      daysSpentPostreview: 'post-revisión',
      'or incomplete': '(o incompleto)',
      'Submission date': 'Fecha de envío',
      summaryInfo: {
        'Average time to publish': 'Tiempo promedio hasta la publicación',
        roundedDays: '{{days}} día',
        roundedDays_plural: '{{days}} días',
        'From submission to published': 'Desde el envío hasta la publicación',
        'Average time to review': 'Tiempo promedio de revisión',
        awaitingRevision: 'Esperando revisión',
        unassigned: 'Sin asignar',
        reviewed: 'Revisado',
      },
      reportTypes: {
        Summmary: 'Resumen',
        Manuscript: 'Manuscrito',
        Editor: 'Editor',
        Reviewer: 'Revisor',
        Author: 'Autor',
      },
      tables: {
        manuscripts: {
          'Manuscript number': 'Número de Manuscrito',
          'Entry date': 'Fecha de Ingreso',
          Title: 'Título',
          Author: 'Autor',
          Editors: 'Editores',
          Reviewers: 'Revisores',
          Status: 'Estado',
          'Published date': 'Fecha de Publicación',
          reviewDuration: 'La revisión tomó <strong>{{durations}}</strong> día',
          reviewDuration_plural:
            'La revisión tomó <strong>{{durations}}</strong> días',
          prevReviewDuration:
            'La revisión previa tomó <strong>{{durations}}</strong> día',
          prevReviewDuration_plural:
            'La revisión previa tomó <strong>{{durations}}</strong> días',
          reviewDurations:
            'Las revisiones tomaron <strong>{{durations}}</strong> días',
          prevReviewDurations:
            'Las revisiones previas tomaron <strong>{{durations}}</strong> días',
        },
        editor: {
          'Editor name': 'Nombre del Editor',
          'Manuscripts assigned': 'Manuscritos asignados',
          'Assigned for review': 'Asignados para revisión',
          Revised: 'Revisados',
          Rejected: 'Rechazados',
          Accepted: 'Aceptados',
          Published: 'Publicados',
        },
        reviewer: {
          'Reviewer name': 'Nombre del Revisor',
          'Review invites': 'Invitaciones a Revisar',
          'Invites declined': 'Invitaciones rechazadas',
          'Reviews completed': 'Revisiones completadas',
          'Average review duration': 'Duración promedio de revisión',
          'Recommended to accept': 'Recomendados para aceptar',
          'Recommended to revise': 'Recomendados para revisar',
          'Recommended to reject': 'Recomendados para rechazar',
          days: '{{days}} día',
          days_plural: '{{days}} días',
        },
        author: {
          'Author name': 'Nombre del Autor',
          revisionRequested: 'Revisión solicitada',
        },
      },
    },
    emailTemplate: {
      'Email Templates': 'Plantillas de Correo Electrónico',
      'New Email Template': 'Nueva Plantilla de Correo Electrónico',
      subject: 'Asunto',
      cc: 'CC',
      ccEditorsCheckboxDescription:
        'Agregar automáticamente editores de manuscritos en "CC" al enviar este correo electrónico (si corresponde)',
      body: 'Cuerpo',
      description: 'Descripción',
      save: 'Guardar',
      update: 'Actualizar',
      addANewEmailTemplate: 'Agregar una nueva plantilla de correo electrónico',
      'Edited on': 'Editado el {{date}}',
      delete: 'Eliminar',
      permanentlyDelete:
        'Eliminar esta plantilla de correo electrónico también eliminará su asignación de tareas y configuraciones. Esta acción no se puede deshacer. ¿Estás seguro de que quieres eliminar?',
      validationMessages: {
        invalidEmail: 'Email no válido',
        duplicateDescription:
          'Ya existe una plantilla con la misma descripción.',
      },
    },
    loginPage: {
      kotahiUses:
        'Alexandrina utiliza ORCID <0>icon</0> para identificar autores y personal.',
      'Login with ORCID': 'Iniciar sesión con ORCID',
      'Register with ORCID': 'Registrarse con ORCID',
    },
    frontPage: {
      recent: 'Publicaciones recientes en {{brandName}}',
      Dashboard: 'Tablero',
      Login: 'Iniciar sesión',
    },
    declineReviewPage: {
      youHaveDeclined:
        'Ha rechazado una invitación para participar en una revisión por pares.',
      reason:
        'Por favor, comparta sus razones para declinar la invitación a continuación.',
      messageHere: 'Su mensaje aquí...',
      dontWantContact: 'No deseo ser contactado nuevamente',
      'Submit Feedback': 'Enviar Comentarios',
      'Decline Invitation': 'Declinar Invitación',
      thanks: 'Gracias por enviar los comentarios.',
    },
    reviewPreviewPage: {
      Summary: 'Resumen',
      Back: 'Atrás',
    },
    sharedReviews: {
      'Other Reviews': 'Otras Revisiones',
    },
    linkExpiredPage:
      'Este enlace de invitación ha caducado. Por favor, póngase en contacto con el administrador del sistema para enviar una nueva invitación.',
    waxEditor: {
      'Front matter tools': 'Herramientas de Portada',
      'Back matter tools': 'Herramientas de Contraportada',
      'Front matter': 'Portada',
      'Change to front matter': 'Cambiar a portada',
      'Funding Group': 'Grupo de Financiamiento',
      'Funding source': 'Fuente de Financiamiento',
      'Change to funding source': 'Cambiar a fuente de financiamiento',
      'Award ID': 'ID de Premio',
      'Change to award ID': 'Cambiar a ID de premio',
      'Funding statement': 'Declaración de Financiamiento',
      'Change to funding statement': 'Cambiar a declaración de financiamiento',
      Keywords: 'Palabras Clave',
      Keyword: 'Palabra Clave',
      'Change to keyword': 'Cambiar a palabra clave',
      'Keyword list': 'Lista de Palabras Clave',
      'Change to keyword list': 'Cambiar a lista de palabras clave',
      Abstract: 'Resumen',
      'Change to abstract': 'Cambiar a resumen',
      Appendices: 'Apéndices',
      Appendix: 'Apéndice',
      'Change to appendix': 'Cambiar a apéndice',
      Acknowledgements: 'Agradecimientos',
      'Change to acknowledgements': 'Cambiar a agradecimientos',
      Glossary: 'Glosario',
      'Glossary section': 'Sección de Glosario',
      'Change to glossary section': 'Cambiar a sección de glosario',
      'Glossary term': 'Término de Glosario',
      'Change to glossary term': 'Cambiar a término de glosario',
      'Glossary item': 'Elemento de Glosario',
      'Change to glossary item': 'Cambiar a elemento de glosario',
      Citations: 'Citaciones',
      'Reference list': 'Lista de Referencias',
      'Change to reference list': 'Cambiar a lista de referencias',
      Reference: 'Referencia',
      'Change to reference': 'Cambiar a referencia',
    },
    manuscriptSubmit: {
      'Current version': 'Versión Actual',
      'Edit submission info': 'Editar información de envío',
      'Manuscript text': 'Texto del Manuscrito',
      'Submit your research object': 'Enviar su objeto de investigación',
      'Errors in your submission': 'Errores en su envío',
      errorsList: 'Hay errores en su envío, por favor corrija lo siguiente:',
      Submit: 'Enviar',
      or: 'o',
      'get back to your submission': 'volver a su envío',
      'Submit a new version': 'Enviar una nueva versión',
      submitVersionButton: 'Enviar una nueva versión...',
      canModify:
        'Puede modificar y volver a enviar una nueva versión de su manuscrito.',
      askedToRevise: `Se le ha solicitado <strong>revisar</strong> su manuscrito;
                  consulte las revisiones y la decisión a continuación. Puede modificar y volver a enviar una
                  nueva versión de su manuscrito.`,
      'Submitted info': 'Información Enviada',
      Reviews: 'Revisiones',
      'No reviews to show': 'No hay revisiones para mostrar.',
      'No completed reviews': 'No hay revisiones completadas.',
      Metadata: 'Metadatos',
    },
    chat: {
      'Your message here...': 'Tu mensaje aquí...',
      Send: 'Enviar',
      noDiscussion:
        'Todavía no hay discusión para este manuscrito. Comienza escribiendo un mensaje abajo.',
      'Unread messages': 'Mensajes no leídos',
      'Admin discussion': 'Discusión de Administrador',
      'Group Manager discussion': 'Discusión de Gerente de Grupo',
      'Show admin discussion': 'Mostrar discusión de Administrador',
      'Show group manager discussion': 'Mostrar discusión de Gerente de Grupo',
      'Discussion with editorial team': 'Discusión con el equipo editorial',
      'Discussion with editor': 'Discusión con el editor',
      'Show Chat': 'Mostrar Chat',
      'Hide Chat': 'Ocultar Chat',
      'Discussion with author': 'Discusión con el autor',
      'Editorial discussion': 'Discusión Editorial',
      edit: 'Editar',
      delete: 'Eliminar',
      Edited: 'Editado',
      'Open video chat': 'Abrir videochat',
      Formatting: 'Formato',
      'Hide formatting': 'Ocultar formato',
    },
    taskManager: {
      list: {
        'Add your first task...': 'Agrega tu primera tarea...',
        'Add a new task': 'Agregar una nueva tarea',
        Title: 'Título',
        Assignee: 'Asignatario',
        'Duration in days': 'Duración en días',
        'Duration/Due Date': 'Duración/Fecha de Vencimiento',
        'Unregistered User': 'Usuario No Registrado',
        'User Roles': 'Roles de Usuario',
        'Registered Users': 'Usuarios Registrados',
        userRoles: {
          Reviewer: 'Revisor',
          Editor: 'Editor',
          Author: 'Autor',
        },
      },
      task: {
        durationDaysNone: 'Ninguno',
        selectAssignee: 'Seleccionar...',
        'Give your task a name': 'Dale un nombre a tu tarea...',
        Edit: 'Editar',
        Delete: 'Eliminar',
        'Click to mark as done': 'Haz clic para marcar como completado',
        statuses: {
          Paused: 'Pausado',
          Pause: 'Pausar',
          'In progress': 'En progreso',
          Continue: 'Continuar',
          Done: 'Completado',
          Start: 'Comenzar',
        },
        unregisteredUser: {
          Email: 'Email',
          Name: 'Nombre',
        },
      },
    },
    tasksPage: {
      'Task Template Builder': 'Constructor de Plantillas de Tareas',
    },
    usersTable: {
      Users: 'Usuarios',
      Name: 'Nombre',
      Created: 'Creado',
      'Last Online': 'Último en línea',
      Roles: 'Roles',
      Delete: 'Eliminar',
      Yes: 'Sí',
      Cancel: 'Cancelar',
      None: 'Ninguno',
    },
    modals: {
      citationPopup: {
        'Select citation': 'Seleccionar cita',
        'No formatted version available':
          'No hay versión formateada disponible.',
        Original: 'Original',
        AnyStyle: 'AnyStyle',
        CrossRef: 'CrossRef',
        Custom: 'Custom',
        Apply: 'Aplicar',
        Edit: 'Editar',
        Back: 'Volver',
        Delete: 'Eliminar',
        'Edit citation': 'Editar cita',
        'Add author': 'Agregar autor',
        'Article title': 'Título del art\u00EDculo',
        Journal: 'Journal',
        Volume: 'Volumen',
        Issue: 'Issue',
        Year: 'Ano',
        Page: 'Página',
        'Citation number': 'Número de la citación',
        'Author family name': 'Apellido del autor',
        'Author given name': 'Nombre del autor',
      },
      inviteDeclined: {
        'Invitation Decline': 'Declinación de la Invitación de {{name}}',
        Declined: 'Declinado: {{dateString}}',
        Reviewer: 'Revisor:',
        Status: 'Estado',
        declinedBadge: 'Declinado',
        'Opted Out': 'Optó por no participar',
        'Declined Reason': 'Motivo de la Declinación',
        'No reason provided': 'No se proporcionó motivo.',
      },
      reviewReport: {
        'Review Report': 'Informe de Revisión de {{name}}',
        'Last Updated': 'Última Actualización: {{dateString}}',
        Reviewer: 'Revisor:',
        Status: 'Estado',
        reviewNotCompleted: 'La revisión aún no se ha completado',
        Delete: 'Eliminar',
        Shared: 'Compartido',
        Recommendation: 'Recomendación',
        'Hide Review': 'Ocultar Revisión',
        'Hide Reviewer Name': 'Ocultar Nombre del Revisor',
      },
      inviteReviewer: {
        'Invite Reviewer': 'Invitar Revisor',
        Shared: 'Compartido',
        'Email Notification': 'Notificación por Correo Electrónico',
        Cancel: 'Cancelar',
        Invite: 'Invitar',
      },
      deleteReviewer: {
        'Delete this reviewer': '¿Eliminar a este revisor?',
        Reviewer: 'Revisor:',
        Ok: 'Ok',
        Cancel: 'Cancelar',
      },
      taskDelete: {
        permanentlyDelete: '¿Eliminar permanentemente esta tarea?',
        Ok: 'Ok',
        Cancel: 'Cancelar',
      },
      taskEdit: {
        'Task details': 'Detalles de la Tarea',
        'Task title': 'Título de la Tarea',
        'Task description': 'Descripción de la tarea',
        Save: 'Guardar',
        'Give your task a name': 'Dale un nombre a tu tarea...',
        Assignee: 'Asignatario',
        'Due date': 'Fecha de Vencimiento',
        'Duration in days': 'Duración en días',
        'Add Notification Recipient': 'Agregar Destinatario de Notificación',
        Recipient: 'Destinatario',
        'Select a recipient': 'Seleccionar un destinatario',
        'Select email template':
          'Seleccionar una plantilla de correo electrónico',
        'Send notification': 'Enviar notificación',
        Send: 'Enviar',
        days: 'días',
        before: 'antes',
        after: 'después',
        'due date': 'fecha de vencimiento',
        'Send Now': 'Enviar Ahora',
        'Show all notifications sent':
          'Mostrar todas las notificaciones enviadas ({{count}})',
        'Hide all notifications sent':
          'Ocultar todas las notificaciones enviadas ({{count}})',
      },
      deleteField: {
        'Permanently delete this field':
          '¿Eliminar permanentemente este campo?',
        Ok: 'Ok',
        Cancel: 'Cancelar',
      },
      deleteCMSLang: {
        'Delete lang': '¿Eliminar idioma?',
        Ok: 'Ok',
        Cancel: 'Cancelar',
      },
      deleteForm: {
        'Permanently delete this form':
          '¿Eliminar permanentemente este formulario?',
        Ok: 'Ok',
        Cancel: 'Cancelar',
      },
      assignUserRole: {
        text:
          '¿Deseas asignar el rol <strong>{{role}}</strong> al usuario {{user}}?',
      },
      removeUserRole: {
        text:
          '¿Deseas quitar el rol <strong>{{role}}</strong> al usuario {{user}}?',
      },
      deleteUser: {
        'Permanently delete user':
          '¿Eliminar permanentemente al usuario {{userName}}?',
        Delete: 'Eliminar',
        Cancel: 'Cancelar',
      },
      cmsPageDelete: {
        Cancel: 'Cancelar',
        Delete: 'Eliminar',
        permanentlyDelete: '¿Eliminar permanentemente la página {{pageName}}?',
      },
      deleteMessage: {
        'Are you sure you want to delete this message?':
          '¿Estás seguro de que deseas eliminar este mensaje?',
      },
      editMessage: {
        'Edit message': 'Editar mensaje',
        save: 'Guardar',
        cancel: 'Cancelar',
      },
      publishError: {
        'Some targets failed to publish':
          'Algunos objetivos no pudieron publicarse.',
        'Publishing error': 'Error de publicación',
      },
      deleteFile: {
        'Are you sure you want to delete this file?':
          '¿Estás seguro/a de que deseas eliminar este archivo?',
      },
    },
    newSubmission: {
      'New submission': 'Nueva presentación',
      'Submission created': 'Presentación creada',
      'Upload Manuscript': 'Subir Manuscrito',
      dragNDrop: 'Arrastra y suelta o haz clic para seleccionar un archivo',
      acceptedFiletypes:
        'Tipos de archivo aceptados: pdf, epub, zip, docx, latex',
      converting:
        'Tu manuscrito se está convirtiendo en una versión directamente editable. Esto podría tomar unos segundos.',
      'Submit a URL instead': 'Enviar una URL en su lugar',
      errorUploading: '{{error}}',
    },
    formBuilder: {
      'New Form': 'Nuevo Formulario',
      'Create Form': 'Crear Formulario',
      'Update Form': 'Actualizar Formulario',
      'Form purpose identifier': 'Identificador del propósito del formulario',
      'Form Name': 'Nombre del Formulario',
      Description: 'Descripción',
      'Submit on Popup': 'Enviar en una ventana emergente',
      submitYes: 'Sí',
      submitNo: 'No',
      'Popup Title': 'Título de la Ventana Emergente',
      'Field Properties': 'Propiedades del Campo',
      'Field type': 'Tipo de Campo',
      'Field title': 'Título del Campo',
      'Field name': 'Nombre (nombre interno del campo)',
      'Field placeholder': 'Marcador de posición del campo',
      internalNameDescription:
        'Utiliza "submission.nombreDelCampoAqui", o uno de los siguientes: "meta.título" para el título del manuscrito, "meta.resumen" para el resumen, "nombreDeArchivo" para Archivos Adicionales, o "visualAbstract" para un Resumen Visual, o "manuscritoArchivo" para el Manuscrito.',
      'Field description': 'Descripción del Campo',
      'Field options': 'Opciones del Campo',
      'Field shortDescription':
        'Título corto (opcional, utilizado en listados concisos)',
      'Field validate': 'Opciones de validación',
      'Field hideFromReviewers': 'Ocultar a los revisores',
      'Field hideFromAuthors': 'Ocultar a los autores',
      'Field permitPublishing': 'Incluir al compartir o publicar',
      'Field publishingTag': 'Etiqueta de Hypothesis',
      'FieldDescription publishingTag':
        'Puedes especificar una etiqueta para usar al compartir este campo como una anotación de Hypothesis',
      'Label to display': 'Etiqueta a mostrar',
      'Color label': 'Etiqueta de color',
      'Enter label': 'Ingresar etiqueta...',
      'Internal name': 'Nombre interno',
      'Enter name': 'Ingresar nombre...',
      'Add another option': 'Agregar otra opción',
      'Delete this option': 'Eliminar esta opción',
      validateInputPlaceholder: 'Seleccionar...',
      'Field parse': 'Análisis especial',
      'Field format': 'Formato especial',
      'Field doiValidation': 'Validar como DOI',
      'Field doiUniqueSuffixValidation':
        'Validar como sufijo DOI y asegurar que sea único',
      'Update Field': 'Actualizar Campo',
      'Correct invalid values before updating':
        'Corregir valores inválidos antes de actualizar',
      'Add Field': 'Agregar Campo',
      'New Field': 'Nuevo Campo',
      'Field inline': 'Campo en línea',
      'Field sectioncss': 'Campo sectioncss',
      typeOptions: {
        Select: 'Selección',
        ManuscriptFile: 'Archivo de Manuscrito',
        SupplementaryFiles: 'Archivos Adjuntos',
        VisualAbstract: 'Archivo de Imagen Única',
        AuthorsInput: 'Lista de colaboradores',
        LinksInput: 'Lista de enlaces (URIs)',
        AbstractEditor: 'Texto enriquecido',
        TextField: 'Texto',
        CheckboxGroup: 'Casillas de verificación',
        RadioGroup: 'Botones de radio',
        undefined: '',
        ThreadedDiscussion: 'Discusión en hilos',
      },
      submission: {
        title: 'Constructor de Formularios de Presentación',
      },
      review: {
        title: 'Constructor de Formularios de Revisión',
      },
      decision: {
        title: 'Constructor de Formularios de Decisión',
      },
    },
    fields: {
      hideFromReviewers: {
        true: 'Sí',
        false: 'No',
      },
      hideFromAuthors: {
        true: 'Sí',
        false: 'No',
      },
      permitPublishing: {
        false: 'Nunca',
        true: 'Ad hoc (El Editor decide al compartir/publicar)',
        always: 'Siempre',
      },
      validate: {
        required: 'Requerido',
        minChars: 'Caracteres mínimos',
        maxChars: 'Caracteres máximos',
        minSize: 'Número mínimo de elementos',
        labels: {
          minChars: 'Valor mínimo de caracteres',
          maxChars: 'Valor máximo de caracteres',
          minSize: 'Valor mínimo de elementos',
        },
      },
      parse: {
        false: 'Ninguno',
        split: 'Dividir por comas',
      },
      format: {
        false: 'Ninguno',
        join: 'Unir con comas',
      },
      doiValidation: {
        true: 'Sí',
        false: 'No',
      },
      doiUniqueSuffixValidation: {
        true: 'Sí',
        false: 'No',
      },
      inline: {
        true: 'Sí',
        false: 'No',
      },
    },
  },
}

export default esLa
