// TODO : Remove after replacing throghout kotahi

const spacing = {
  a: '1px',
  b: '2px',
  c: '3px',
  d: '5px',
  e: '7.5px',
  f: '15px',
  g: '30px',
  h: '45px',
  i: '60px',
  j: '90px',
  k: '135px',
}

export default spacing
