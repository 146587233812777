module.exports = [
  {
    color: 'green',
    label: 'Accept',
    value: 'accept',
  },
  {
    color: 'orange',
    label: 'Revise',
    value: 'revise',
  },
  {
    color: 'red',
    label: 'Reject',
    value: 'reject',
  },
]
