import { css } from 'styled-components'

export default {
  // TODO
  // -- input padding: breaking the grid?
  // -- small placeholder text? maybe by default?

  Opener: css`
    height: auto;
  `,
}
